<template>
  <div class="main row m-0">
    <div class="col-md-6 left-column justify-content-center center">
      <header class="d-flex justify-content-center">
        <img v-if="whitelabelReady" class="logo" alt="Omni Business" :src="whitelabelLogo" />
        <!-- <div class="language">
          <OptLangRadio />
        </div> -->
      </header>
      <span style="    background: #752DE6;
    color: white;
    font-size: 12px;
    padding: 5px 11px;
    border-radius: 100px;
    font-weight: 500;"><a style="color: white;" href="https://yup.chat">{{ $t('app.back-to-website') }}</a></span>
      <div class="row mt-3">
        <template v-if="routing">
          <div class="col-md-12">
            <router-view />
          </div>
        </template>
        <div class="col-12 sub-intro" v-else>
          <h1>
            {{ $t('auth.welcome') }}
          </h1>
          <h2>
            {{ $t('auth.subtitle') }}
          </h2>
          <div class="row">
            <div class="col-12 col-md-4 mb-4">
              <ButtonComponent @click="routing = true" is-block>Login</ButtonComponent>
            </div>
            <div class="col-12 col-md-4">
              <ButtonComponent variant="secondary" @click="launchRegister()" is-block>{{ $t('app.register') }} </ButtonComponent>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 right-column d-none d-md-block">
      <h3 style="color: white;text-align: center;margin-top: 2em;font-size: 33px;">Bem vindo ao Experience Business!</h3>
      <aside style="height: 50%;" class="samples d-flex align-items-center justify-content-center">
        <div class="samples-bg">
          <img src="@/assets/img/image.svg" alt="Omni Business" />
        </div>
      </aside>
      <p style="text-align: center;
    color: white;
    font-size: 22px;
    font-weight: bold;
    margin-top: -2em;">Experimente nossa plataforma gratuitamente por 30 dias!</p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { load } from 'recaptcha-v3';
import DarkMode from '@/assets/mixins/detectDark';
import OptLangRadio from '@/components/OptLangRadio.vue';
import ButtonComponent from '@/components/ui/ButtonComponent.vue';
import Logo from '@/assets/logo-v2.svg';

export default {
  name: 'AuthIntro',
  components: {
    // OptLangRadio,
    ButtonComponent,
  },
  mixins: [DarkMode],
  watch: {
    $route(to, from) {
      // if (from.path === '/2fa') {
      //   this.routing = true;
      // } else if (to.path === '/login') {
      //   this.routing = false;
      // }

      if (to.path === '/login') {
        this.routing = false;
      }
    },
  },
  data() {
    return {
      routing: this.$router.currentRoute.path !== '/login',
      logo: null,
    };
  },
  computed: {
    whitelabelReady() {
      return !!this.$store?.state?.admin?.whitelabel?.id;
    },
    whitelabelLogo() {
      return this.$store?.state?.admin?.whitelabel?.logo ?? Logo;
    },
    whitelabel() {
      return this.$store?.state?.admin?.whitelabel ?? null;
    },
  },
  mounted() {
    const hostname = window.location.hostname;

    switch (hostname) {
      case 'smsycorporativo.algartelecom.com.br':
        this.companyName = 'Algar Telecom';
        break;
      default:
        console.info('Default favicon');
    }
  },
  methods: {
    async launchRegister() {
      await this.$router.push('/register');
      this.routing = true;
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  height: 120vh;
  padding: 0; // Remove the padding from main

  .left-column {
    background-color: white;
    padding: 53px 37px;

    @media (min-width: 768px) {
      padding: 50px 180px;
    }

    @media (max-width: 1500px) {
      padding: 50px 50px;
    }
  }

  .right-column {
    background: radial-gradient(63.72% 70.71% at 50% 50%, #6E3DBC 0%, #4B1D93 100%);
    padding: 39px 23px;

    @media (min-width: 768px) {
      padding: 115px 180px;
    }

    @media (max-width: 1500px) {
      padding: 50px 50px;
    }
  }

  header {
    margin-bottom: 66px;
  }

  .logo {
    width: 126px;
    height: 30px;
  }

  .sub-intro {
    margin-top: 10vh;

    h1 {
      font-size: 23px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-align: center;

      @media (min-width: 768px) {
        font-size: 50px;
        text-align: left;
      }
    }

    h2 {
      color: #747374;
      font-size: 19px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: center;

      @media (min-width: 768px) {
        font-size: 32px;
        text-align: left;
      }
    }
  }
}
</style>
